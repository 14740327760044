import React from "react"
import { Link } from "gatsby"
import Button from "react-bootstrap/Button"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Pagination from "../components/Pagination"

const News = ({ location, pageContext }) => {
  const { news, limit, skip, numNewsPages, currentPage } = pageContext
  const pageLink = "/news"

  return (
    <Layout location={location}>
      <SEO
        title="Regional Roundup"
        description="News from Bundaberg and surrounds"
      />
      <div className="row">
        <div className="col text-center news-heading">
          <h1>Regional Roundup</h1>
          <p>News from Bundaberg and surrounds</p>
        </div>
      </div>
      <div className="row">
        {news.slice(skip, skip + limit).map((article) => (
          <div className="col-md-4 article" key={article.id}>
            <h3>{article.title}</h3>
            <p>{article.description}</p>
            <Link to={`/article/${article.id}`}>
              <Button variant="primary" bsPrefix="cln">
                Read More
              </Button>
            </Link>
          </div>
        ))}
      </div>
      <Pagination
        currentPage={currentPage}
        itemsPerPage={limit}
        totalItems={news.length}
        totalPages={numNewsPages}
        pageLink={pageLink}
      />
    </Layout>
  )
}

export default News
